import React, { useState } from "react";
import axios from "axios";
import "./App.css";


function App() {
  const [step, setStep] = useState(1); // Step in the workflow
  const [selectedService, setSelectedService] = useState(null); // Stores the selected service
  const [selectedOptions, setSelectedOptions] = useState({}); // Tracks selected options for the service
  const [description, setDescription] = useState('');
  const [customerInfo, setCustomerInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
  });


  const options = {
    1: [
      { label: "Home Improvement", value: "Home Improvement" },
      { label: "TV Mounting", value: "TV Mounting" },
    ],
    "TV Mounting": [
      { label: "Small TV (32-50 inches)", value: "small_tv" },
      { label: "Medium TV (51-65 inches)", value: "medium_tv" },
      { label: "Large TV (66+ inches)", value: "large_tv" },
    ],
    "Wall Type": [
      { label: "Drywall", value: "drywall" },
      { label: "Concrete", value: "concrete" },
      { label: "Brick", value: "brick" },
    ],
    "Home Improvement": [
      { label: "Shelving", value: "shelving" },
      { label: "Furniture Assembly", value: "furniture_assembly" },
      { label: "Curtain and Shades Installation", value: "curtain_shades" },
    ],
    "Additional Options": [
      { label: "Wall Bracket", value: "wall_bracket" },
      { label: "Wires Hiding", value: "wires_hiding" },
      { label: "LED Lights", value: "led_lights" },
    ],
    "Bracket Type": [
      { label: "Full Motion Bracket", value: "full_motion" },
      { label: "Tilt Bracket", value: "tilt" },
    ],
    "Wires Hiding Options": [
      { label: "Inside the Wall", value: "inside_wall" },
      { label: "Plastic Strip Cover", value: "plastic_strip" },
    ],
  };

  const handleChoice = (choice) => {
    console.log(`Step: ${step}, Choice: ${choice}`); // Debug step and choice
  
    if (step === 1) {
      setSelectedService(choice);
      if (choice === "Home Improvement") {
        setStep("home_improvement");
      } else if (choice === "TV Mounting") {
        setStep(2);
      }
    } else if (step === "home_improvement") {
      setSelectedOptions((prev) => ({ ...prev, home_improvement_type: choice }));
      setStep("additional_info_home");
    } else if (step === "additional_info_home") {
      setSelectedOptions((prev) => ({
        ...prev,
        home_improvement_description: description,
      }));
      setStep("customer_info");
    } else if (step === 2) {
      setSelectedOptions((prev) => ({ ...prev, tv_size: choice }));
      setStep(3);
    } else if (step === 3) {
      setSelectedOptions((prev) => ({ ...prev, wall_type: choice }));
      setStep(4);
    } else if (step === 4) {
      setSelectedOptions((prev) => {
        const currentOptions = prev.additional_options || [];
        return {
          ...prev,
          additional_options: currentOptions.includes(choice)
            ? currentOptions.filter((option) => option !== choice)
            : [...currentOptions, choice],
        };
      });
    } else if (step === 5) {
      setSelectedOptions((prev) => ({ ...prev, bracket_type: choice }));
      proceedToNextStep(); // Proceed based on wires hiding selection
    } else if (step === 6) {
      setSelectedOptions((prev) => {
        const updated = { ...prev, wires_hiding_option: choice };
        setStep("customer_info");
        return updated;
      });
    }
  };
  
  

  const proceedToNextStep = () => {
    const { additional_options = [] } = selectedOptions;
  
    if (step === 4) {
      // Check selected options and proceed accordingly
      if (additional_options.includes("wall_bracket") && additional_options.includes("wires_hiding")) {
        setStep(5); // Both selected: go to "Bracket Type" first
      } else if (additional_options.includes("wall_bracket")) {
        setStep(5); // Only "Wall Bracket" selected: go to "Bracket Type"
      } else if (additional_options.includes("wires_hiding")) {
        setStep(6); // Only "Wires Hiding" selected: go to "Wires Hiding Options"
      } else {
        setStep("customer_info"); // Neither selected: skip to customer info
      }
    } else if (step === 5) {
      // After "Bracket Type", check if wires hiding is selected and proceed
      if (selectedOptions.additional_options?.includes("wires_hiding")) {
        setStep(6); // Go to "Wires Hiding Options"
      } else {
        setStep("customer_info"); // Skip to customer info
      }
    }
  };
  
  

  const handleCustomerInfoChange = (field, value) => {
    setCustomerInfo((prev) => ({ ...prev, [field]: value }));
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const submitOrder = (options = selectedOptions) => {
    const payload = {
      ...customerInfo,
      service: selectedService,
      tv_size: options.tv_size || null,
      wall_type: options.wall_type || null,
      additional_options: options.additional_options || [],
      bracket_type: options.bracket_type || null,
      wires_hiding_option: options.wires_hiding_option || null,
      home_improvement_type: options.home_improvement_type || null,
      home_improvement_description: options.home_improvement_description || null,
    };

    console.log("Payload to Submit:", payload);

    axios
      .post("https://floatingelectronics.com/api/create-order/", payload)
      .then((response) => {
        console.log("Order created:", response.data);
        setStep(null); // End the flow
      })
      .catch((error) => {
        console.error("Error creating order:", error);
      });
  };
  

  return (
    <div className="container">
      {step === 1 ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Choose the service you are interested in</p>
          {options[1].map((option) => (
            <button
              key={option.value}
              className={option.value === "TV Mounting" ? "secondary" : ""}
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
        </>
      ) : step === "customer_info" ? (
        <>
        <div className="customer-info-container">
          <h1>Customer Information</h1>
          <p>Please provide your details to complete the order:</p>
          <div className="input-group">
            <input
              type="text"
              placeholder="Full Name"
              value={customerInfo.name}
              onChange={(e) => handleCustomerInfoChange("name", e.target.value)}
            />
            <input
              type="email"
              placeholder="Email"
              value={customerInfo.email}
              onChange={(e) => handleCustomerInfoChange("email", e.target.value)}
            />
            <input
              type="tel"
              placeholder="Phone Number"
              value={customerInfo.phone_number}
              onChange={(e) => handleCustomerInfoChange("phone_number", e.target.value)}
            />
          </div>
          <button className="submit" onClick={() => submitOrder()}>
            Submit Order
          </button>
        </div>
      </>
      ) : step === "home_improvement" ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Choose a Home Improvement service:</p>
          {options["Home Improvement"].map((option) => (
            <button
              key={option.value}
              className="secondary"
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
        </>
      ) : step === "additional_info_home" ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Describe your project (additional information):</p>
          <textarea
            className="description-input"
            placeholder="Type your project description here..."
            value={description}
            onChange={handleDescriptionChange} // Updates description state
          ></textarea>
          <button className="submit" onClick={() => handleChoice()}>
            Submit
          </button>

        </>    
      ) : step === 2 ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Choose your TV size</p>
          {options["TV Mounting"].map((option) => (
            <button
              key={option.value}
              className="secondary"
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
        </>
      ) : step === 3 ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Choose your wall type</p>
          {options["Wall Type"].map((option) => (
            <button
              key={option.value}
              className="secondary"
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
        </>
      ) : step === 4 ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Select any additional services (you can choose multiple):</p>
          {options["Additional Options"].map((option) => (
            <button
              key={option.value}
              className={
                selectedOptions.additional_options?.includes(option.value)
                  ? "secondary selected"
                  : "secondary"
              }
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
          <button className="submit" onClick={proceedToNextStep}>
            Proceed
          </button>
        </>
      ) : step === 5 ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Choose your bracket type:</p>
          {options["Bracket Type"].map((option) => (
            <button
              key={option.value}
              className="secondary"
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
        </>
      ) : step === 6 ? (
        <>
          <h1>Floating Electronics</h1>
          <p>Choose your wires hiding option:</p>
          {options["Wires Hiding Options"].map((option) => (
            <button
              key={option.value}
              className="secondary"
              onClick={() => handleChoice(option.value)}
            >
              {option.label}
            </button>
          ))}
        </>
      ) : (
        <>
          <h2>Thank you for submitting your information!</h2>
          <p>Your choices have been submitted. We'll get back to you with the quote soon.</p>
        </>
      )}
    </div>
  );
}

export default App;
